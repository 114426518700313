import type { Blog as _Blog } from "$docs/types"
export type Blog = _Blog

import how_to_watch_youtube_together from "./how-to-watch-youtube-together"
import release_1_1 from "./release-1-1"
import release_1_2 from "./release-1-2"
import release_1_0 from "./release-1-0"
import how_to_listen_to_music_together from "./how-to-listen-to-music-together"
import frequently_asked_questions from "./frequently-asked-questions"
import alternative_to_watch2gether from "./alternative-to-watch2gether"

const blogs = {
    "how-to-watch-youtube-together": how_to_watch_youtube_together,
    "release-1-1": release_1_1,
    "release-1-2": release_1_2,
    "release-1-0": release_1_0,
    "how-to-listen-to-music-together": how_to_listen_to_music_together,
    "frequently-asked-questions": frequently_asked_questions,
    "alternative-to-watch2gether": alternative_to_watch2gether,
} as const

export default blogs
export { how_to_watch_youtube_together, release_1_1, release_1_2, release_1_0, how_to_listen_to_music_together, frequently_asked_questions, alternative_to_watch2gether }
